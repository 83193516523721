<template>
  <div class="merge-fields-group-wrapper" :class="{ 'mb-2': !marginBottomEmtpy }">
    <h6 v-if="!hideTitle" class="h6">Merge fields</h6>
    <div v-click-outside="onClickOutside" class="mergefield-dropdown">
      <div class="mergefield-dropdown-element" :class="{ 'open': open }">
        <b-button class="mt-1" :variant="buttonVariant" :size="dropdownSize" @click="toggleOpen">Personalization <i class="uil uil-angle-down"></i></b-button>
        <ul>
          <li v-if="!hideContactDropdown" class="dropdown-item"><a href="javascript:;">Lead User Info <i class="uil uil-angle-right"></i></a>
            <ul>
              <li @click="appendField('[lead_name]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Name</a>
              </li>
              <li @click="appendField('[lead_first_name]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> First Name</a>
              </li>
              <li @click="appendField('[lead_last_name]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Last Name</a>
              </li>
              <li @click="appendField('[lead_email]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Email Address</a>
              </li>
              <li @click="appendField('[lead_phone]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Phone</a>
              </li>
              <li v-for="customField in customFields" :key="customField.id" @click="appendField(`[custom_${customField.name }]`)">
                <a href="javascript:;"><i class="uil uil-copy"></i> {{ customField.name }}</a>
              </li>
            </ul>
          </li>
          <li class="dropdown-item"><a href="javascript:;">Lead Company Information <i class="uil uil-angle-right"></i></a>
            <ul>
              <li @click="appendField('[lead_business_name]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Business Name</a>
              </li>
              <li @click="appendField('[lead_business_phone]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Business Phone</a>
              </li>
              <li @click="appendField('[lead_business_url]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Business URL</a>
              </li>
              <li @click="appendField('[lead_business_address]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Business Address</a>
              </li>
              <li @click="appendField('[lead_business_city]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Business City</a>
              </li>
              <li @click="appendField('[lead_business_state]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Business State</a>
              </li>
              <li @click="appendField('[lead_business_zip]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Business Zip</a>
              </li>
              <li @click="appendField('[lead_business_country]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Business Country</a>
              </li>
            </ul>
          </li>
          <li class="dropdown-item"><a href="javascript:;">Review Info <i class="uil uil-angle-right"></i></a>
            <ul>
              <li @click="appendField('[reviewers_name]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Reviewer's Name</a>
              </li>
              <li @click="appendField('[reviewers_review]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Reviewer's Review</a>
              </li>
            </ul>
          </li>
          <li class="dropdown-item"><a href="javascript:;">Agency Information <i class="uil uil-angle-right"></i></a>
            <ul>
              <li @click="appendField('[agency_name]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Agency Name</a>
              </li>
              <li @click="appendField('[agency_user_first_name]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Agency User First Name</a>
              </li>
              <li @click="appendField('[agency_user_last_name]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Agency User Last Name</a>
              </li>
              <li @click="appendField('[agency_phone]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Agency Phone</a>
              </li>
              <li @click="appendField('[agency_email]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Agency Email</a>
              </li>
              <li @click="appendField('[agency_street_address]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Agency Street</a>
              </li>
              <li @click="appendField('[agency_city]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Agency City</a>
              </li>
              <li @click="appendField('[agency_state]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Agency State</a>
              </li>
              <li @click="appendField('[agency_zip]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Agency Zip</a>
              </li>
              <li @click="appendField('[agency_country]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Agency Country</a>
              </li>
              <li @click="appendField('[qr_code]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> QR Code</a>
              </li>
              <li @click="appendField('[link]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Link</a>
              </li>
            </ul>
          </li>
          <li class="dropdown-item"><a href="javascript:;">Misc. <i class="uil uil-angle-right"></i></a>
            <ul>
              <li @click="appendField('[page_break]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Page Break</a>
              </li>
              <li @click="appendField('[custom_field_1]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Custom Field 1</a>
              </li>
              <li @click="appendField('[custom_field_2]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Custom Field 2</a>
              </li>
              <li @click="appendField('[custom_field_3]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Custom Field 3</a>
              </li>
              <li @click="appendField('[custom_field_4]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Custom Field 4</a>
              </li>
              <li @click="appendField('[custom_field_5]')">
                <a href="javascript:;"><i class="uil uil-copy"></i> Custom Field 5</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    buttonVariant:{
      type:String,
      default:'outline-dark'
    },
    mceField: {
      type: Object,
      required: false,
      default: null,
    },
    textField: {
      type: Object,
      required: false,
      default: null,
    },
    showRedemption: {
      type: Boolean,
      default: false,
    },
    hideExpireDate: {
      type: Boolean,
      default: false,
    },
    showKeyword: {
      type: Boolean,
      default: false,
    },
    showContactLink: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    dropdownSize: {
      type: String,
      default: '',
    },
    hideContactDropdown: {
      type: Boolean,
      default: false,
    },
    hideSamcartDropdown: {
      type: Boolean,
      default: false,
    },
    marginBottomEmtpy: {
      type: Boolean,
      default: false,
    },
    hideGoogleLinks:{
      type:Boolean,
      default:false
    }
  },

  data: function () {
    return {
      open: false,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    useSamcartIntegration() {
      return !this.hideSamcartDropdown && this.user && this.user.business && this.user.business.use_integration && this.user.business.has_integrations.samcart
    },

    useStaticCoupon() {
      return this.$store.getters['keyword/useStaticCoupon']
    },

    useGotoWebinarIntegration() {
      return !this.hideContactDropdown && this.user && this.user.business && this.user.business.has_integrations.goto
    },

    customFields() {
      return this.$store.getters["auth/customFields"];
    },
  },

  methods: {
    onClickOutside() {
      this.open = false
    },
    toggleOpen() {
      this.open = true
    },
    appendField(field) {
      if (this.textField && this.textField.$el) {
        this.textField.$el.querySelector('textarea').focus()
        document.execCommand('insertText', false, field)
      } else {
        this.$emit('selected', field)
      }
      this.open = false
    }
  }
}
</script>
<style lang="scss" scoped>
.merge-fields-group-wrapper {
  .mergefield-dropdown,
  .mergefield-dropdown ul,
  .mergefield-dropdown li,
  .mergefield-dropdown a {
    margin: 0;
    padding: 0;
    line-height: normal;
    list-style: none;
    display: block;
    position: relative;
  }
  .mergefield-dropdown {
    display: inline-block;
  }

  .mergefield-dropdown ul {
    opacity: 0;
    position: absolute;
    top: 100%;
    left: -9999px;
    z-index: 999;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
  }

  .mergefield-dropdown-element.open>ul {
    left: 0;
    opacity: 1;
    z-index: 1000;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
    background: #fff;
    animation-name: DropDownSlide;
    animation-duration: .3s;
    animation-fill-mode: both;
  }

  .mergefield-dropdown li:hover>ul {
    left: 0;
    opacity: 1;
    z-index: 1000;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
    animation-name: DropDownSlide;
    animation-duration: .3s;
    animation-fill-mode: both;
  }

  .mergefield-dropdown ul li:hover>ul {
    top: 0;
    left: 100%;
  }

  .mergefield-dropdown li {
    cursor: default;
    float: left;
    white-space: nowrap;
  }

  .mergefield-dropdown ul li {
    float: none;
  }

  /* sub width */
  .mergefield-dropdown ul {
    min-width: 12em;
    -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  }

  /* root */
  .mergefield-dropdown a {
    padding: 0 10px;
    color: #333;
    font-weight: normal;
    font-size: 16px;
    line-height: 40px;
    text-decoration: none;
  }

  /* root: hover/persistence */
  .mergefield-dropdown a:hover,
  .mergefield-dropdown a:focus,
  .mergefield-dropdown li:hover a {
    background: #fff;
    color: #333;
  }

  /* 2 */
  .dropdown-item a,
  .mergefield-dropdown li li a,
  .mergefield-dropdown li:hover li a {
    padding: 10px 10px;
    background: #fff;
    color: #333;
    font-size: 14px;
    line-height: normal;
    display: flex;
    justify-content: flex-start;
  }

  .dropdown-item a {
    justify-content: space-between;
  }

  /* 2: hover/persistence */
  .mergefield-dropdown li:hover li a:hover,
  .mergefield-dropdown li:hover li a:focus,
  .mergefield-dropdown li:hover li:hover a {
    background: #efefef;
  }
}
</style>
